<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <v-dialog
      v-model="dialog.active"
      persistent
      width="290px"
      :disabled="disabled"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :value="value"
          readonly
          v-on="on"
          :class="`${classes}`"
          :disabled="disabled"
        />
      </template>
      <v-date-picker
        v-if="dialog.active"
        v-model="date"
        :min="startDate"
        :locale="locale"
        scrollable
      />
      <v-time-picker
        v-if="dialog.active"
        v-model="time"
        format="24hr"
        full-width
      >
        <v-spacer />
        <v-btn
          icon
          color="error"
          @click="onDeleteDateTime"
        >
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
        <v-btn
          icon
          color="default"
          @click="dialog.active = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
        <v-btn
          icon
          color="primary"
          @click="onSaveDateTime"
        >
          <v-icon>
            mdi-check
          </v-icon>
        </v-btn>
      </v-time-picker>
    </v-dialog>
  </td>
</template>

<script>
export default {
  name: 'ChecklistCellTypeDateTime',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  data() {
    return {
      time: '00:00',
      date: '1970-01-01',
      dialog: {
        active: false
      }
    }
  },
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    classes() {
      return this.state.classes
    },
    disabled() {
      return this.state.disabled
    },
    startDate() {
      const settings = this.cell.settings
      if (settings.date_start_today) {
        const date = new Date()
        if (this.offset) {
          date.setDate(new Date(date).getDate() + this.offset)
        }
        return date.toISOString().substr(0, 10)
      }
      return ''
    },
    offset() {
      const settings = this.cell.settings
      if (settings.date_start_today) {
        return settings.date_start_offset_days || 0
      }
      return 0
    },
    value() {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      const value = builder.getCellValue(index, this.cell, this.clone)
      return value
    },
    locale() {
      const builder = this.data.ChecklistBuilder
      const locale = builder.getSetting('LOCALE') || 'en-us'
      return locale.toLowerCase()
    }
  },
  created() {
    const builder = this.data.ChecklistBuilder
    let date

    if (this.value) {
      date = builder.getDateObject(this.value)
      if (isNaN(date)) {
        date = new Date()
      }
    } else if (this.startDate) {
      date = new Date(this.startDate)
    } else {
      date = new Date()
    }

    this.date = builder.getDateISO(date, false)
    this.time = builder.getTime(date)
  },
  mounted() {
    this.applySQLData()
  },
  methods: {
    applySQLData() {
      const builder = this.data.ChecklistBuilder
      if (typeof builder.applySQLData === 'function') {
        builder.applySQLData(this.checklist.index, this.cell, this.dataViewer).then((result) => {
          if (!result || typeof result !== 'object' || !result.value) {
            return
          }
          this.save(result.value)
        })
      }
    },
    onSaveDateTime() {
      const date = this.formatDate(this.date) || ''
      const time = this.time || ''
      const value = `${date} ${time}`
      this.save(value)
    },
    onDeleteDateTime() {
      this.save('')
    },
    save(value) {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      const previousValue = this.value
      builder.evalValues({
        index: index,
        dataEditor: this.cell,
        dataViewer: this.dataViewer,
        cloneIndex: this.clone,
        encode: false,
        saveCallback: undefined,
        ignoreAutoSaveSetting: false,
        value: value,
        previousValue: previousValue
      })
      this.dialog.active = false
    },
    formatDate(date) {
      const builder = this.data.ChecklistBuilder
      return builder.getDate(date)
    }
  }
}
</script>
